import React from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "./TextImageBlock"
import PressBlock from "../Blocks/PressBlock"

const Blocks = (props) => {
  const blocks = props.blocks

  return (
    <>
      {blocks && blocks.map((block, index) => {
        const { __typename: type } = block

        if (type === "ContentfulSimpleBlock") {
          return <SimpleBlock {...block} key={index} />
        }

        if (type === "ContentfulTextImageBlock") {
          return <TextImageBlock {...block} key={index} />
        }

        if (type === "ContentfulPageBlockGroup") {
          return <PageBlockGroup {...block} key={index} />
        }

        if (type === "ContentfulPressBlock") {
          return <PressBlock {...block} key={index} />
        }
      })}
    </>
  )
}

export default Blocks

import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import CtaButton from "../Core/CtaButton"
import Text from "../Blocks/Text"

const BlockContainer = styled.div`
  padding: 5vw;
`

const Background = styled.div`
  padding: 6.6vmax 6vw;
  background: ${(props) =>
    props.bg === "Blue"
      ? colors.royalBlue
      : props.bg === "Pink"
      ? colors.pink
      : colors.lightGrey};
`

const ContentContainer = styled.div`
  padding: 0 4%;
`

const Heading = styled.h2`
  color: ${(props) => (props.bg === "White" ? colors.royalBlue : colors.white)};
  line-height: 1.5;
  margin-top: calc(-1em - 1.5%);
  position: relative;
  z-index: 1;
  display: block;

  span {
    background: ${(props) =>
      props.highlight === "Blue"
        ? colors.royalBlue
        : props.highlight === "Yellow"
        ? colors.yellow
        : "transparent"};
    webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0 3%;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
    width: 150%;
    font-size: 3rem;
  }
`

const Content = styled.div`
  margin-top: 18px;
  color: ${(props) => (props.bg === "White" ? colors.black : colors.white)};

  p {
    margin-bottom: 1rem;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  b {
    ${fonts.workSansBold};
  }
`

const ButtonContainer = styled.div`
  margin-top: 17px;
`

const StyledRow = styled(Row)`
  position: relative;
  max-width: 1600px;
  width: 100%;
  margin: auto;
`

const TextImageBlock = ({
  heading,
  headingHighlight,
  media,
  content,
  ctaLabel,
  ctaLink,
  backgroundColor,
  imagePosition,
}) => {
  return (
    <BlockContainer>
      <Background bg={backgroundColor}>
        <StyledRow>
          {imagePosition === "Right" ? (
            <>
              <Col lg={8} xxl={9} lgOrder="last">
                <GatsbyImage
                  image={media.gatsbyImageData}
                  alt={media.description || heading}
                />
              </Col>
              <Col lg={4} xxl={3} alignSelf="center">
                <ContentContainer>
                  <Heading highlight={headingHighlight} bg={backgroundColor}>
                    <span>{heading}</span>
                  </Heading>
                  <Content bg={backgroundColor}>
                    <Text content={content} references={content.references} />
                  </Content>
                  {ctaLabel && ctaLink && (
                    <ButtonContainer>
                      <CtaButton
                        url={ctaLink}
                        label={ctaLabel}
                        blue={backgroundColor === "White"}
                      />
                    </ButtonContainer>
                  )}
                </ContentContainer>
              </Col>
            </>
          ) : (
            <>
              <Col lg={8} xxl={9}>
                <GatsbyImage
                  image={media.gatsbyImageData}
                  alt={media.description || heading}
                />
              </Col>
              <Col lg={4} xxl={3} alignSelf="center">
                <ContentContainer>
                  <Heading highlight={headingHighlight} bg={backgroundColor}>
                    <span>{heading}</span>
                  </Heading>
                  <Content bg={backgroundColor}>
                    <Text content={content} references={content.references} />
                  </Content>
                  {ctaLabel && ctaLink && (
                    <ButtonContainer>
                      <CtaButton
                        url={ctaLink}
                        label={ctaLabel}
                        blue={backgroundColor === "White"}
                      />
                    </ButtonContainer>
                  )}
                </ContentContainer>
              </Col>
            </>
          )}
        </StyledRow>
      </Background>
    </BlockContainer>
  )
}

export default TextImageBlock
